import { useEffect, useState } from "react";
import { Link, useLocation } from "wouter";
import {
  createProtocol,
  fetchProtocolSummaries,
  GeoLocation,
  postProtocolOrderNumber,
} from "./api/protocol";
import { Route, RouteKind, serializeRoute } from "./route";
import { ProtocolId, ProtocolSummary, UserType } from "./types";
import { QrCodePrintElement } from "./qr-code-print";
import { useCookies } from "react-cookie";
import { postInvite } from "./api/invite";

function getCurrentPosition(): Promise<GeoLocation> {
  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        resolve({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
          accuracy: position.coords.accuracy,
        });
      },
      (error) => {
        console.log("Failed to get current position", error);
        reject(error);
      },
    );
  });
}

type ProtocolSummaryElementProps = {
  protocolId: ProtocolId;
  protocolSummary: ProtocolSummary;
};
function ProtocolSummaryElement({
  protocolId,
  protocolSummary,
}: ProtocolSummaryElementProps) {
  const { address, date, carCount, recorderName, orderNumber } =
    protocolSummary;
  const href = serializeRoute({
    kind: RouteKind.Protocol,
    protocolId,
  });

  function handleOrderNumberBlur(event: React.FocusEvent<HTMLInputElement>) {
    const newOrderNumber = event.target.value;
    postProtocolOrderNumber(protocolId, newOrderNumber);
    // TODO: Handle error.
  }

  return (
    <tr>
      <td>{address}</td>
      <td>{new Date(date).toLocaleDateString()}</td>
      <td>{recorderName}</td>
      <td>{carCount}</td>
      <td>
        <input
          type="text"
          defaultValue={orderNumber}
          onBlur={handleOrderNumberBlur}
        />
      </td>
      <td>
        <Link href={href}>Öffnen</Link>
      </td>
    </tr>
  );
}

function InviteButton(): JSX.Element {
  const [showModal, setShowModal] = useState(false);
  const [inviteeEmail, setInviteeEmail] = useState("");
  const [inviteeName, setInviteeName] = useState("");
  const [showError, setShowError] = useState(false);

  const handleInvite = async () => {
    try {
      setShowError(false);
      await postInvite({ inviteeEmail, inviteeName });
      setShowModal(false);
      setInviteeEmail("");
    } catch (error) {
      console.error("Failed to invite user", error);
      setShowError(true);
    }
  };

  return (
    <div>
      <button className="btn btn-dark" onClick={() => setShowModal(true)}>
        Mitarbeiter einladen
      </button>

      {showModal && (
        <div className="modal fade show d-block" tabIndex={-1} role="dialog">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Einladen</h5>
                <button
                  type="button"
                  className="btn-close"
                  aria-label="Close"
                  onClick={() => setShowModal(false)}
                ></button>
              </div>
              <div
                className="modal-body"
                style={{ display: "flex", flexDirection: "column", gap: 12 }}
              >
                {showError && (
                  <div className="alert alert-danger">
                    Ein Fehler ist aufgetreten. Ist die Email-Adresse korrekt?
                  </div>
                )}
                <input
                  type="email"
                  className="form-control"
                  placeholder="E-Mail-Adresse eingeben"
                  value={inviteeEmail}
                  onChange={(e) => setInviteeEmail(e.target.value)}
                  required
                />
                <input
                  type="text"
                  className="form-control"
                  placeholder="Name"
                  value={inviteeName}
                  onChange={(e) => setInviteeName(e.target.value)}
                  required
                />
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => setShowModal(false)}
                >
                  Abbrechen
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleInvite}
                >
                  Einladen
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export function HomeElement() {
  const [protocolSummaries, setProtocolSummaries] = useState<null | Map<
    ProtocolId,
    ProtocolSummary
  >>(null);
  const [fetchFailed, setFetchFailed] = useState(false);
  const [, setLocation] = useLocation();

  const [cookies] = useCookies(["user"]);
  const userType = cookies.user as UserType;

  const showLoadingSpinner = protocolSummaries == null && !fetchFailed;

  useEffect(() => {
    (async () => {
      try {
        setProtocolSummaries(await fetchProtocolSummaries());
        setFetchFailed(false);
      } catch (err) {
        console.error("Failed to fetch protocol summaries on home screen", err);
        setFetchFailed(true);
      }
    })();
  }, []);

  function handleFetchAgain() {
    (async () => {
      setFetchFailed(false);
      try {
        setProtocolSummaries(await fetchProtocolSummaries());
        setFetchFailed(false);
      } catch (err) {
        console.error("Failed to fetch protocol summaries on home screen", err);
        setFetchFailed(true);
      }
    })();
  }

  async function handleAddProtocolClick() {
    const geoLocation = await (async () => {
      try {
        return await getCurrentPosition();
      } catch (err) {
        console.error("Failed to get current position", err);
        return null;
      }
    })();

    const protocolId = await createProtocol(geoLocation);
    const route: Route = {
      kind: RouteKind.Protocol,
      protocolId,
    };
    setLocation(serializeRoute(route));
  }

  return (
    <main
      style={{
        marginLeft: 8,
        marginRight: 8,
        display: "flex",
        flexDirection: "column",
        gap: 8,
        paddingTop: 8,
        paddingBottom: 8,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: 16,
        }}
      >
        <QrCodePrintElement />
        {userType === UserType.Admin && <InviteButton />}
      </div>
      <h2>
        Protokolle{" "}
        <input
          className="btn btn-dark btn-sm"
          type="button"
          value="+"
          onClick={handleAddProtocolClick}
        />
      </h2>
      <div>
        <div
          className="spinner-border"
          role="status"
          style={{
            opacity: showLoadingSpinner ? 1 : 0,
            transitionProperty: "opacity",
            // Wait a bit before showing the spinner, but hide immediately.
            transitionDelay: showLoadingSpinner ? "0.5s" : "0s",
            transitionDuration: showLoadingSpinner ? "0.3s" : "0s",
          }}
        >
          <span className="visually-hidden">Loading...</span>
        </div>
        {fetchFailed && (
          <div className="alert alert-danger" role="alert">
            Ein Fehler ist aufgetreten. Bitte versuchen Sie es später erneut.
            <div>
              <input
                type="button"
                className="btn btn-dark"
                onClick={handleFetchAgain}
              />
            </div>
          </div>
        )}
        {protocolSummaries != null && (
          <table className="table">
            <thead>
              <tr>
                <th>Adresse</th>
                <th>Datum</th>
                <th>Protokollant</th>
                <th>Autos</th>
                <th>Auftragsnummer</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {Array.from(protocolSummaries).map(
                ([protocolId, protocolSummary]) => (
                  <ProtocolSummaryElement
                    key={protocolId}
                    protocolId={protocolId}
                    protocolSummary={protocolSummary}
                  />
                ),
              )}
            </tbody>
          </table>
        )}
      </div>
    </main>
  );
}
